@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"










































































































































































































.exam-header
  position: fixed
  color: white
  width: 100%
  top: 0
  z-index: 999
  padding: 0 48px
  display: flex
  justify-content: space-between
  align-items: center
  box-sizing: border-box
  height: 64px
  background: #5196D6
  +anim(300ms)

  +mq-l
    padding: 0 32px

  +mq-m
    padding: 0 24px

  +mq-s
    height: 64px
    padding: 0 16px

  &__title
    &__assessment
      margin-bottom: 2px
      opacity: .85
      font-size: 14px
      +anim(300ms)

      +mq-s
        font-size: 13px

    &__exam
      font-size: 20px
      +anim(300ms)

      +mq-s
        font-size: 18px

  &__switch-dark-mode
    margin: 0 19px 0 10px

  // Actions
  &__actions--small

    &.--scroll
      display: none

    .actions__button
      background: white
      margin: 0 16px 0 22px

  &__actions
    display: flex
    align-items: center

    &__pause
      +mq-s
        display: none

    .btn ~ .btn
      margin-left: 8px

.header__alert
  position: fixed
  display: flex
  justify-content: center
  padding: 16px
  box-sizing: border-box
  z-index: 998
  color: white
  width: 100%
  top: 64px

  +mq-s
    padding: 8px 16px
    justify-content: start
    top: 64px

    p
      font-size: 13px

  &.--fail
    background: $color_danger

  &.--success
    background: $color_success


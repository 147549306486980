@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"











































































































































































































































































































































































































.review-modal

  &--disabled
    pointer-events: none

  .modal
    padding: 0

    +mq_review_modal
      min-width: auto
      width: 100vw
      height: 100vh
      border-radius: 0
      overflow-y: auto
      scroll-behavior: smooth

    &__title
      padding: 40px
      padding-bottom: 24px

      +mq-m
        padding: 24px

      +mq-s
        padding: 16px

    &__footer
      margin: 0

  .header__info
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 8px
    color: $color-ink-light

    +mq-m
      flex-direction: column
      align-items: flex-start

    &__description
      box-sizing: border-box
      padding-right: 48px
      border-right: 1px solid transparentize($color-ink-lighter, .65)

      +mq-s
        padding: 0px
        border-right: none

      small
        margin-top: 8px
        display: block
        color: shade-color($color-ink-lighter, 1%)

    &__group
      display: flex
      min-width: 400px
      justify-content: flex-end

      +mq-m
        flex-direction: column
        justify-items: center
        align-items: flex-start
        min-width: 220px

      +mq-m
        flex-direction: row
        justify-content: space-between
        align-content: space-between
        min-width: 100%

      &__items
        flex-shrink: 0
        margin-left: 48px
        margin-right: 16px

        +mq-m
          margin-left: 0px
          margin-right: 0px
          margin-top: 32px

        .info-right__label
          opacity: .7

  &__body

    &-details

      box-sizing: border-box
      display: grid
      grid-gap: $size-s $size-xl

      grid-auto-flow: column
      padding: 0 $size-xl $size-l $size-xl
      padding-bottom: $size-l

      +mq-l
        grid-gap: $size-xs $size-m

      +mq-m
        grid-template-columns: repeat(3, 1fr)
        grid-template-rows: repeat(8, 1fr)
        padding: 0 $size-m $size-l $size-m

      +mq-s
        padding: $size-s
        grid-template-columns: repeat(2, 1fr)
        grid-template-rows: repeat(15, 1fr)
        grid-gap: $size-xs $size-s

      &.--simple
        grid-template-columns: repeat(4, 1fr)
        grid-template-rows: repeat(6, 1fr)

      &.--custom
        grid-template-columns: repeat(5, 1fr)
        grid-template-rows: repeat(6, 1fr)

        +mq-m
          grid-template-columns: repeat(2, 1fr)
          grid-template-rows: repeat(15, 1fr)

      .answer
        display: flex
        align-items: center
        background: tint-color($color-ink-lighter, 80%)
        border-radius: 6px
        padding: 12px 16px
        box-sizing: border-box
        cursor: pointer
        user-select: none
        backface-visibilty: hidden
        +anim(200ms)

        &:hover
          transform: scale(1.03)
          background: tint-color($color-ink-lighter, 25%)
          +anim(200ms)

        &:focus
          outline: 0
          transform: scale(1.03)
          box-shadow: 0 0 0 2px $color-primary inset

        &:active
          transform: scale(1.05)
          background: $color-ink-lighter
          +anim(200ms)

        &__order
          margin-right: 16px

        &__marked-option
          display: inline-flex
          border-radius: 100px
          width: 28px
          height: 28px
          align-items: center
          justify-content: center
          color: white
          background: $color-ink-light

        &--blank
          font-size: 14px
          color: transparentize($color-ink, .5)
          mix-blend-mode: multiply

  &__footer
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0px 32px 32px 32px

    +mq_review_modal
      position: fixed
      bottom: 0
      width: 100%
      left: 0
      background: white
      box-sizing: border-box
      box-shadow: 0 -1px 12px rgba(0,0,0,.1)
      padding: 8px 16px
      z-index: 100

    .btn--float
      text-decoration: none

    .btn ~ .btn
      min-width: 160px

.answer__tooltip .tooltip-inner
  padding: 16px

  h5
    line-height: 1
    margin-bottom: 6px
    font-size: 15px

  p
    font-size: 14px
    line-height: 1.4

.container-slider
  display: flex
  flex-direction: column
  padding-bottom: 96px

  .VueCarousel-navigation
    position: absolute
    bottom: 0
    height: 30px
    left: 0
    right: 0
    margin: 0 auto
    z-index: 0

    .VueCarousel-navigation-button
      font-size: 20px
      margin-top: -3px
      color: #C4C4C4
      opacity: 1
      outline: none

      &--disabled
        color: #C4C4C4
        opacity: 1

  .VueCarousel-pagination
    position: relative
    z-index: 1
    margin: 0 auto

    .VueCarousel-dot
      margin-top: 0 !important
      width: 12px !important
      height: 12px !important
      outline: none
.confirmation-modal
  .sas-modal__body
    padding: 0 32px 24px

  &__body
    h3
      font-size: $font-size-heading-6
      max-width: 85%
      padding-bottom: $size-m

  &__footer
    display: flex
    justify-content: space-between

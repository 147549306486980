@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"

































































































.exam-footer
  +anim(200ms)
  backface-visibility: hidden

  &.--disabled
    pointer-events: none

@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"





































































































































































































































































































































































































































$dark-mode-background-color: #2D3848
$dark-mode-text-color: #C9CCCF
$dark-mode-lightest-text-color: #F7F7F7
$dark-mode-exam-header-color: #1A202C
$dark-mode-box-color: #3A4556
$dark-mode-selected-option-color: #4d8af0
$dark-mode-footer-background-color: #4A5568
$dark-mode-footer-border: 1px solid #666E75
$dark-mode-pagination-background-color: #53627B
$dark-mode-activeted-option-color: #999EA3
$dark-mode-review-answer-color: #455265
$dark-mode-review-marked-answer-color: #3B4351
$dark-mode-scratch-color: #C0D9F6
$gradient-parameters: 90deg, $dark-mode-box-color -6%, rgba(8, 9, 11, 0.5) 94%
$dark-mode-scratch-gradient: linear-gradient($gradient-parameters)

.exam
  background: #fafafa
  box-sizing: border-box

  &--disabled
    pointer-events: none

  .connectivity--online
    background: $color_success

  &-body
    height: 100%
    max-width: 860px
    margin: 0 auto
    padding-top: 25px
    padding-bottom: 120px
    box-sizing: border-box
    color: $color-ink

    &.--first-item
      padding-top: 160px

      +mq-s
        padding-top: 180px

    +mq-l
      max-width: calc(100vw - 48px)

    +mq-s
      max-width: calc(100vw - 32px)

  .sas-input__field.--large
    height: 44px

  &.--dark-mode
    background: $dark-mode-background-color

    .sas-button
      &.--secondary,
      &.--tertiary
        background-color: transparent
        box-shadow: none
        border: none
        color: $dark-mode-text-color!important

    .exam-header
      background: $dark-mode-exam-header-color

      &__title__assessment,
      &__title__exam
        color: $dark-mode-text-color

      &__switch-dark-mode
        margin: 0 11px 0 10px

      .exam-timer__content-timer
        color: $dark-mode-text-color
        border-color: $dark-mode-text-color

      .popover__menu,
      .actions__button
        background-color: $dark-mode-footer-background-color
        color: $color-white

    .exam-body
      .exam-item__header
        background: $dark-mode-background-color
        color: $dark-mode-lightest-text-color

      .exam-item__statement
        color: $dark-mode-lightest-text-color

      .exam-item__options
        .box,
        .item-alternative__body__letter
          background: $dark-mode-box-color

        .item-alternative__body__letter.is-selected
          background-color: $dark-mode-selected-option-color

        .item-alternative__body__letter
          color: $dark-mode-lightest-text-color

        .item-alternative__button
          background: $dark-mode-scratch-gradient

        .item-alternative__body__scratch
          border: 1px solid $dark-mode-scratch-color

    .exam-footer
      .exam-pagination,
      .exam-pagination--mobile__bar
        background-color: $dark-mode-footer-background-color
        border-top: $dark-mode-footer-border

      .exam-pagination__actions__page .sas-input__field
        background: $dark-mode-background-color
        color: $dark-mode-text-color

      .exam-pagination__pages__list
        .sas-dropdown-menu,
        .sas-dropdown-menu__item
          background: $dark-mode-pagination-background-color!important

        .exam-pagination__item
          border-radius: 8px
          color: $dark-mode-lightest-text-color
          border: 1px solid $dark-mode-lightest-text-color

          &--filled
            border: 1px solid $dark-mode-selected-option-color

          &--actived
            background: $dark-mode-activeted-option-color
            color: $color-white
            border: 1px solid $dark-mode-activeted-option-color

      .exam-pagination--mobile__pages
        background: $dark-mode-pagination-background-color

        .exam-pagination--mobile__item
          border-radius: 8px
          color: $dark-mode-lightest-text-color
          border: 1px solid $dark-mode-lightest-text-color

          &--filled
            border: 1px solid $dark-mode-selected-option-color
          &--actived
            background: $dark-mode-activeted-option-color
            color: $color-white
            border: 1px solid $dark-mode-activeted-option-color

    .review-modal .modal
      background-color: $dark-mode-background-color

      .modal__close
        color: $dark-mode-lightest-text-color

      .header__title,
      .header-info__description,
      .info-right__label,
      .info-right__text--custom-tag
        color: $dark-mode-text-color

      .review-modal__body-details
        .answer
          background-color: $dark-mode-review-answer-color

          .answer__order
            color: $dark-mode-lightest-text-color

          .answer__marked-option
            background: $dark-mode-review-marked-answer-color

          .answer--blank
            color: $dark-mode-lightest-text-color
            mix-blend-mode: normal

      .review-modal__footer
        border-top: $dark-mode-footer-border
        background: $dark-mode-footer-background-color

    .confirmation-modal .sas-modal
      background: $dark-mode-background-color!important

      .confirmation-modal__body
        color: $dark-mode-text-color

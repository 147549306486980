@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"











































































































































































.exam-header__actions__finish .feather
  overflow: visible !important

.sas-button.--tertiary
    padding: 0

.exam-pagination--mobile

  &__progress
    position: absolute
    top: 0
    left: 0
    .progress-bar__foreground
      fill: $color-primary

  &__left
    display: flex
    align-items: center

    span
      pointer-events: none
      margin-right: 8px
      width: 30px
      height: 30px

  &__bar
    display: flex
    overflow: auto
    z-index: 100
    align-items: center
    padding: 4px 16px
    box-sizing: border-box
    background: $color-ink-lightest
    position: fixed
    width: 100%
    height: 56px
    right: 0
    bottom: 0
    justify-content: space-between
    border-top: 1px solid transparentize($color-ink-lighter, .65)

  &__item
    flex-shrink: 0
    line-height: 100%
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    font-size: 12px
    font-weight: 600
    text-align: center
    color: $color-ink-light
    width: 28px
    height: 28px
    border-radius: 5px
    padding: 0
    background: transparent
    border: 1.4px solid transparentize($color-ink-light, 0.6)
    cursor: pointer
    margin: 0 auto
    +anim(200ms)

    &.--actived
      +anim(150ms)
      color: $color-ink
      background: transparentize($color-ink-light, 0.5)

    &.--filled
      +anim(150ms)
      color: white
      background: $color-primary

    &:hover
      +anim(150ms)
      transform: scale(1.06)

    &:focus
      outline: 0
      box-shadow: 0 0 0 2px transparentize($color-primary, .1)

  &__pages
    z-index: 99
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(32px, 1fr))
    grid-gap: 12px $size-xs
    align-items: center
    background: shade-color($color-ink-lightest, 3%)
    box-shadow: 0 -1px 32px transparentize($color-ink, .6)
    box-sizing: border-box
    position: fixed
    padding: $size-s
    bottom: 56px
    left: 0
    width: 100%
    backface-visibilty: hidden

    +mq-l
      grid-template-columns: repeat(10, 1fr)
      grid-gap: 12px $size-xxs

    +mq-s
      grid-template-columns: repeat(10, 1fr)
      grid-gap: 12px 0
      padding: $size-s $size-xxs

    &:focus
      outline: 0

  &__actions
    display: flex
    align-items: center

    .sas-button
      width: 115px
      margin: 0 3px

    .btn
      cursor: pointer
      margin-right: 5px

    .btn ~ .btn
      margin-left: 8px

#examPagesToggle:disabled
  opacity: 1


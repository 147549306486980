@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"






























































































.popover
  display: inline-block
  width: max-content
  position: relative

  &.--disabled
    pointer-events: none

  &__menu
    position: absolute
    top: calc(100% - 4px)
    width: max-content
    display: flex
    align-items: flex-start
    flex-direction: column

    &:focus
      outline: 0

    &--right
      right: 0

    &--left
      left: 0

    .btn
      border-radius: 0 !important
      padding: 12px 20px !important
      height: auto !important

    .btn ~ .btn
      border-top: 1px solid $color-ink-lightest !important


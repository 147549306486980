@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"



















































































































































.exam-pagination
  display: flex
  overflow: auto
  z-index: 100
  align-items: center
  padding: 8px 32px
  box-sizing: border-box
  background: $color-ink-lightest
  position: fixed
  width: 100%
  height: 64px
  right: 0
  bottom: 0
  justify-content: flex-end
  +anim(200ms)
  backface-visibility: hidden

  &__item
    flex-shrink: 0
    display: inline-block
    font-size: 12px
    font-weight: 600
    text-align: center
    color: $color-ink-light
    width: 32px
    height: 28px
    line-height: 23px !important
    border-radius: 5px
    padding: 0
    background: transparent
    border: 1px solid transparentize($color-ink-light, 0.6)
    cursor: pointer
    +anim(200ms)

    &--actived
      +anim(150ms)
      color: $color-ink
      background: transparentize($color-ink-light, 0.5)

    &--filled
      +anim(150ms)
      color: white
      background: $color-primary

    &:hover
      +anim(150ms)
      transform: scale(1.06)

    &:focus
      outline: 0
      box-shadow: 0 0 0 2px transparentize($color-primary, .1)

  &__pages
    width: 100%
    display: grid
    align-items: center
    grid-template-columns: repeat(10, 1fr)
    grid-gap: 8px
    margin-right: 16px

    +mq-l
      grid-gap: 6px

    &__list
      position: fixed
      left: $element-size-l

      .sas-dropdown-menu
        background: $color-ice !important

        &__item
          background: $color-ice !important

          &:hover
            background: $color-ice
  &__actions
    display: flex
    align-items: center

    &__previous
      margin-right: $size-s

    &__page
      margin-right: $size-s
      width: 57px

      .sas-input__field
        text-align: center
        background: $color-white
        -webkit-appearance: none
        margin: 0
        padding: 0 14px

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button
          -webkit-appearance: none
          margin: 0

    .btn
      cursor: pointer

    .btn ~ .btn
      margin-left: 8px
